@import url('https://fonts.googleapis.com/css?family=Staatliches&display=swap');
h1 {
    font-family: 'Staatliches', cursive;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #ffffff;
    height: 100%;
}

a {
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
}

.tdBefore {
    color: black;
}

#root {
    height: 100%;
}

.posCircle {
    background: red;
    border-radius: 50%;
    margin: 10px;
    height: 15px;
    width: 15px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
    border: 1px solid #8e8e8e;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 57, 57, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.negCircle {
    background: #19b919;
    border-radius: 50%;
    margin: 10px;
    height: 15px;
    width: 15px;
    box-shadow: 0 0 0 0 rgba(25, 185, 25, 1);
    transform: scale(1);
    animation: pulse2 2s infinite;
    border: 1px solid #8e8e8e;
}

@keyframes pulse2 {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(25, 185, 25, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.rejectedCircle {
    background: #fff825;
    border-radius: 50%;
    margin: 10px;
    height: 15px;
    width: 15px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse3 2s infinite;
    border: 1px solid #8e8e8e;
}

@keyframes pulse3 {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 248, 37, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@media screen and (min-width: 640px) {
    .pivoted {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.or-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
}

.vl {
    border-left: 2px solid grey;
    height: 60px;
    align-self: center;
}

.t1 {
    padding: 5px 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: left;
    align-self: center;
}

.card-container {
    display: flex;
    justify-content: space-between;
}

.card-content {
    line-height: 1.7;
    font-weight: 600;
    font-size: 10px;
    width: 335px;
    padding: 5px;
}

.card-info-title {
    color: #171e9e;
}

.edit-user-modal {
    max-width: 600px;
}

@media screen and (max-width: 790px) {
    .list-container {
        width: 350px !important;
    }
}

.table-head-title {
    font-weight: 600;
    text-align: center;
    padding: 3px 0;
}

.table-head-filter {
    padding: 0 3px 10px 3px;
}